import React, { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import ImageUploadComponent from "./asset";
import "../subscription/subscription.css";
import authentication from "../../utils/authentication";

const SubscriptionAdd = ({
  plan,
  open,
  setOpen,
  openHandler,
  selectedid,
  onupdate,
  fetchSubscriptions,
}) => {
  const [data, setData] = useState({
    plan: "",
    planTag: "",
    slogan: "",
    price: 0,
    discount: 0,
    isActive: true,
    isFreePlan: false,
    isLive: false,
    duration: 0,
    image: null,
    features: {
      curatedProfiles: 0,
      monthlyCuratedProfiles: 0,
      videoSnippets: false,
      mysyncOrOursync: [],
      subscriptionPauseDays: 0,
      assistedPreferenceChanges: false,
      dedicatedSoulSyncer: false,
      accessToFullFeatures: false,
      hideNamesVisible: false,
      benifits: [],
    },
  });

  useEffect(() => {
    if (plan) {
      fetchPlanData();
    }
  }, [plan, selectedid]);

  const fetchPlanData = async () => {
    try {
      const response = await axios(`subscription/${selectedid}`, "get", {});
      setData(response.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const handleChange = (e, field, nestedField) => {
    if (e === "" && field === "image") {
      setData({
        ...data,
        image: "",
      });
    } else {
      const value =
        e.target.type === "checkbox" ? e.target.checked : e.target.value;

      setData((prevData) => {
        let isFreePlan = prevData.isFreePlan;
        if (field === "plan" && value.toLowerCase() === "free") {
          isFreePlan = true;
        } else if (field === "plan") {
          isFreePlan = false;
        } else if (field === "image") {
          return {
            ...prevData,
            [field]: e.target.files[0],
          };
        }

        if (nestedField) {
          if (
            nestedField === "mysyncOrOursync" &&
            e.target.type === "checkbox"
          ) {
            return {
              ...prevData,
              isFreePlan,
              [field]: {
                ...prevData[field],
                [nestedField]: e.target.checked
                  ? [...prevData[field][nestedField], e.target.value]
                  : prevData[field][nestedField].filter(
                      (item) => item !== e.target.value
                    ),
              },
            };
          } else if (nestedField.startsWith("benifit")) {
            const benifitIndex = parseInt(nestedField.slice(-1)) - 1;
            const updatedBenifits = [...prevData.features.benifits];
            updatedBenifits[benifitIndex] = value;

            return {
              ...prevData,
              isFreePlan,
              features: {
                ...prevData.features,
                benifits: updatedBenifits,
                monthlyCuratedProfiles:
                  prevData.features.curatedProfiles /
                  Math.floor(prevData.duration / 30),
              },
            };
          } else {
            return {
              ...prevData,
              isFreePlan,
              [field]: {
                ...prevData[field],
                [nestedField]: value,

                ...(field === "features" &&
                  nestedField === "monthlyCuratedProfiles" && {
                    curatedProfiles:
                      value * Math.floor(Number(prevData.duration) / 30),
                  }),
              },
            };
          }
        } else {
          return {
            ...prevData,
            isFreePlan,
            [field]: value,
            ...(field === "duration" && {
              features: {
                ...prevData.features,
                curatedProfiles:
                  Number(prevData.features.monthlyCuratedProfiles) *
                  Math.floor(Number(value) / 30),
              },
            }),
          };
        }
      });
    }
  };
  console.log(plan._id);
  const fileChangeHandler = useCallback(
    async (e) => {
      if (e?.target?.files[0]) {
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        };
        const form = new FormData();
        form.append("file", e?.target?.files[0]);

        try {
          const res = await axios("users/video", "post", form, headers);

          await axios(`subscription/edit/${plan?._id}`, "put", {
            image: res.data.url,
          });
          fetch();
          toast.success("Subscription image updated successfully");
          openHandler();
        } catch (error) {
          if (error?.response?.status === 401) {
            toast.error(error.response.data.message);
            setTimeout(() => {
              authentication.removeAuthentication();
            }, 1000);
          } else if (error?.response?.status === 403) {
            toast.error(error.response.data.message);
            setTimeout(() => {
              authentication.reirectToUsers();
            }, 1000);
          } else {
            toast.error("Something went wrong");
          }
        }
      }
    },
    [plan?._id]
  );

  const submitHandler = async () => {
    if (data.plan?.trim() === "") {
      toast.error("Subscription name is required");
      return;
    }
    if (Number.isInteger(data.monthlyCuratedProfiles)) {
      toast.error("Monthlty Curated Profile Must be in Integer");
      return;
    }

    if (
      data.features.benifits[0]?.length > 40 ||
      data.features.benifits[1]?.length > 40 ||
      data.features.benifits[2]?.length > 40 ||
      data.features.benifits[3]?.length > 40 ||
      data.features.benifits[4]?.length > 40 ||
      data.features.benifits[5]?.length > 40
    ) {
      toast.error("benifit should be in 40 characters or less");
      return;
    }
    if (selectedid) {
      try {
        await axios(`subscription/edit/${selectedid}`, "put", data);

        toast.success("Subscription updated successfully");
        setOpen(false);
        fetchSubscriptions();
      } catch (error) {
        if (error?.response?.status === 401) {
          toast.error(error.response.data.message);
          setTimeout(() => {
            authentication.removeAuthentication();
          }, 1000);
        } else if (error?.response?.status === 403) {
          toast.error(error.response.data.message);
          setTimeout(() => {
            authentication.reirectToUsers();
          }, 1000);
        } else {
          toast.error("Something went wrong");
        }
      }
    } else {
      try {
        if (data.image != null) {
          const AssetUrl = await uploadImg(data.image);
          data.image = AssetUrl;
        }

        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        await axios("subscription/create", "post", data, {
          headers,
        });
        toast.success("Subscription added successfully");

        setOpen(false);
        fetchSubscriptions();
      } catch (error) {
        if (error?.response?.status === 401) {
          toast.error(error.response.data.message);
          setTimeout(() => {
            authentication.removeAuthentication();
          }, 1000);
        } else if (error?.response?.status === 403) {
          toast.error(error.response.data.message);
          setTimeout(() => {
            authentication.reirectToUsers();
          }, 1000);
        } else {
          toast.error("Something went wrong");
        }
      }
    }
  };

  const uploadImg = async (img) => {
    if (img) {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };
      const form = new FormData();
      form.append("file", img);

      try {
        const res = await axios("users/video", "post", form, headers);

        return res.data.url;
      } catch (error) {
        if (error?.response?.status === 401) {
          toast.error(error.response.data.message);
          setTimeout(() => {
            authentication.removeAuthentication();
          }, 1000);
        } else if (error?.response?.status === 403) {
          toast.error(error.response.data.message);
          setTimeout(() => {
            authentication.reirectToUsers();
          }, 1000);
        } else {
          toast.error("Something went wrong");
        }
      }
    }
  };

  return (
    <div className="modal-container">
      {/* <div className="modal-opener">
        <button className="primary" onClick={openHandler}>
          Add Subscription
        </button>
      </div> */}
      <div className={open ? "modal active" : "modal"}>
        <div className="wrapper">
          <div className="header">
            <h2> {selectedid ? `Update` : `Add`} Subscription</h2>
            <button type="button" className="danger" onClick={openHandler}>
              Close
            </button>
          </div>
          <div className="content">
            <div className="input-wrapper">
              <div className="form-section">
                <h4>Icon</h4>
                <ImageUploadComponent
                  type="subscription-add"
                  value={data?.image}
                  onChange={fileChangeHandler}
                />
              </div>
            </div>
            <div className="form-section">
              <h4> Sync Options</h4>
              <div className="checkboxes">
                <div className="checkbox-wrapper">
                  <input
                    type="checkbox"
                    value="MySync"
                    checked={data.features.mysyncOrOursync.includes("MySync")}
                    onChange={(e) =>
                      handleChange(e, "features", "mysyncOrOursync")
                    }
                  />
                  <label>My Sync</label>
                </div>
                <div className="checkbox-wrapper">
                  <input
                    type="checkbox"
                    value="OurSync"
                    checked={data.features.mysyncOrOursync.includes("OurSync")}
                    onChange={(e) =>
                      handleChange(e, "features", "mysyncOrOursync")
                    }
                  />
                  <label>Our Sync</label>
                </div>
              </div>
            </div>
            {/* <div className="form-section">
              <h4> Active & Live Status</h4>
              <div className="checkboxes">
                <div className="checkbox-wrapper">
                  <input
                    type="checkbox"
                    checked={data.isActive}
                    onChange={(e) => handleChange(e, "isActive")}
                  />
                  <label>Is Active</label>
                </div>
                <div className="checkbox-wrapper">
                  <input
                    type="checkbox"
                    checked={data.isLive}
                    onChange={(e) => handleChange(e, "isLive")}
                  />
                  <label>Is Live</label>
                </div>
              </div>
            </div> */}
            <div className="form-section">
              <h4>Information</h4>

              <div className="input-wrapper">
                <label>
                  Name <span className="required">*</span>
                </label>
                <input
                  value={data.plan}
                  onChange={(e) => handleChange(e, "plan")}
                />
              </div>

              <div className="input-wrapper">
                <label>
                  Slogan <span className="required">*</span>
                </label>
                <input
                  value={data.slogan}
                  onChange={(e) => handleChange(e, "slogan")}
                />
              </div>
              <div className="input-wrapper">
                <label>
                  Tag <span className="required">*</span>
                </label>
                <input
                  value={data.planTag}
                  onChange={(e) => handleChange(e, "planTag")}
                />
              </div>

              <div className="flexed">
                {!data?.isFreePlan && (
                  <>
                    <div className="input-wrapper">
                      <label>
                        Price <span className="required">*</span>
                      </label>
                      <input
                        value={data.price}
                        onChange={(e) => handleChange(e, "price")}
                      />
                    </div>
                    <div className="input-wrapper">
                      <label>
                        Discount <span className="required">*</span>
                      </label>
                      <input
                        value={data.discount}
                        onChange={(e) => handleChange(e, "discount")}
                      />
                    </div>
                  </>
                )}
                <div className="input-wrapper">
                  <label>
                    Duration <span className="required">*</span>
                  </label>

                  <select
                    style={{ width: "200px" }}
                    value={data.duration}
                    onChange={(e) => handleChange(e, "duration")}
                  >
                    <option value="">select duration</option>
                    <option value="30">1 Months</option>
                    <option value="60">2 Months</option>
                    <option value="90">3 Months</option>
                    <option value="120">4 Months</option>
                    <option value="150">5 Months</option>
                    <option value="180">6 Months</option>
                    <option value="210">7 Months</option>
                    <option value="240">8 Months</option>
                    <option value="270">9 Months</option>
                    <option value="300">10 Months</option>
                    <option value="330">11 Months</option>
                    <option value="365">1 year</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="form-section">
              <h4>Features</h4>

              <div className="flexed">
                <div className="input-wrapper">
                  <label>
                    Curated Profiles <span className="required">*</span>
                  </label>
                  <input value={data.features.curatedProfiles} />
                </div>

                <div className="input-wrapper">
                  <label>
                    Monthly Curated Profiles <span className="required">*</span>
                  </label>
                  <input
                    value={data.features.monthlyCuratedProfiles}
                    onChange={(e) =>
                      handleChange(e, "features", "monthlyCuratedProfiles")
                    }
                  />
                  {/* <select
                    value={data.features.curatedProfiles}
                    onChange={(e) =>
                      handleChange(e, "features", "monthlyCuratedProfiles")
                    }
                  >
                    {[...Array(20).keys()].map((value) => (
                      <option key={value * 3 + 3} value={value + 1}>
                        {value + 1}
                      </option>
                    ))}
                  </select> */}
                </div>

                <div className="input-wrapper">
                  <label>
                    Subscription Pause Days <span className="required">*</span>
                  </label>
                  <input
                    value={data.features.subscriptionPauseDays}
                    onChange={(e) =>
                      handleChange(e, "features", "subscriptionPauseDays")
                    }
                  />
                </div>
              </div>
            </div>

            <div className="form-section">
              <h4>Benifits</h4>

              {[1, 2, 3, 4, 5, 6].map((index) => (
                <div className="input-wrapper" key={index}>
                  <label>Benifit {index}</label>
                  <input
                    value={data.features.benifits[index - 1]}
                    onChange={(e) =>
                      handleChange(e, "features", `benifit${index}`)
                    }
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="footer">
            <button onClick={submitHandler} type="button" className="primary">
              {selectedid ? `Update` : `Submit`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionAdd;
