import React, { useState, useCallback, useEffect, useRef } from "react";
import { Link, useParams, Location, useLocation } from "react-router-dom";
import {
  getMatchesStatistics,
  getMatchUsers,
  getAllMatchSyncBrackets,
  pushProfile,
  markMetExploring,
  markMetNotInterested,
} from "../../apis/users";
import { toast } from "react-toastify";
import authentication from "../../utils/authentication";

const SeeMatches = () => {
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(true);
  const id = useParams()?.id;
  const gender = useLocation()?.search?.split("=")[1];
  const [data, setData] = useState([]);
  const [matchesStatistcis, setMatchesStatistices] = useState({});
  const [matchType, setMatchType] = useState("All");
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [ourSyncBrackets, setOurSyncBrackets] = useState([]);
  const [mySyncBrackets, setMySyncBrackets] = useState([]);
  const [pushedProfiles, setPushedProfiles] = useState([]);
  const [unlockedProfiles, setUnlockedProfiles] = useState([]);

  const fetchMatchesStatistcis = useCallback(async () => {
    try {
      const apiResponse = await getMatchesStatistics(id, gender);

      if (apiResponse?.data?.data) {
        setMatchesStatistices({ ...apiResponse?.data?.data });
      } else {
        setMatchesStatistices({});
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        toast.error(error?.response?.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error?.response?.status === 403) {
        toast.error(error?.response?.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      } else {
        toast.error("Something went wrong");
      }
    }
  }, [id, gender]);

  const fetchUsers = useCallback(
    async (num) => {
      try {
        const apiResponse = await getMatchUsers(id, users, matchType, num);
        let temp = 1;
        if (apiResponse?.data?.data?.total % 50 > 0) {
          temp = parseInt(apiResponse?.data?.data?.total / 50) + 1;
        } else {
          temp = parseInt(apiResponse?.data?.data?.total / 50);
        }
        setLastPage(temp);
        setData([...apiResponse?.data?.data?.users]);
        setPushedProfiles([...apiResponse?.data?.data?.pushedProfiles]);
        setUnlockedProfiles(apiResponse?.data?.data?.unlockedProfiles || []);
        setLoading(false);
        tableRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      } catch (error) {
        setLoading(false);
        if (error?.response?.status === 401) {
          toast.error(error?.response?.data.message);
          setTimeout(() => {
            authentication.removeAuthentication();
          }, 1000);
        } else if (error?.response?.status === 403) {
          toast.error(error?.response?.data.message);
          setTimeout(() => {
            authentication.reirectToUsers();
          }, 1000);
        } else {
          toast.error("Something went wrong");
        }
      }
    },
    [matchType, users]
  );

  const pushProfileHandler = useCallback(
    async (userId) => {
      try {
        await pushProfile(id, userId);
        fetchUsers(page);
        toast.success("Profile pushed successfully");
        setLoading(true);
      } catch (error) {
        if (error?.response?.status === 401) {
          toast.error(error?.response?.data.message);
          setTimeout(() => {
            authentication.removeAuthentication();
          }, 1000);
        } else if (error?.response?.status === 403) {
          toast.error(error?.response?.data.message);
          setTimeout(() => {
            authentication.reirectToUsers();
          }, 1000);
        } else {
          toast.error("Something went wrong");
        }
      }
    },
    [id, page]
  );

  const pageHandler = useCallback(
    (isIncrement) => {
      setLoading(true);
      setPage(isIncrement ? page + 1 : page - 1);
      fetchUsers(isIncrement ? page + 1 : page - 1);
    },
    [page]
  );

  const fetchMatchSyncBrackets = useCallback(async () => {
    try {
      const apiResponse = await getAllMatchSyncBrackets();
      if (apiResponse?.data?.data) {
        setMySyncBrackets([...apiResponse?.data?.data?.mySync]);
        setOurSyncBrackets([...apiResponse?.data?.data?.ourSync]);
      } else {
        setMySyncBrackets([]);
        setOurSyncBrackets([]);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error?.response?.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error?.response?.status === 403) {
        toast.error(error?.response?.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      } else {
        toast.error("Something went wrong");
      }
    }
  }, []);

  useEffect(() => {
    fetchMatchesStatistcis();
    fetchMatchSyncBrackets();
  }, [id, gender]);

  useEffect(() => {
    if (matchType === "All" || users.length > 0) {
      setLoading(true);
      setPage(1);
      fetchUsers(1);
    } else {
      setData([]);
    }
  }, [matchType, users]);

  const markMetExploringHandler = useCallback(
    async (userId) => {
      try {
        await markMetExploring(id, userId);
        toast.success("Match marked as Met - Exploring");
        setLoading(true);
        fetchUsers(1);
        fetchMatchesStatistcis();
      } catch (error) {
        if (error?.response?.status === 401) {
          toast.error(error?.response?.data.message);
          setTimeout(() => {
            authentication.removeAuthentication();
          }, 1000);
        } else if (error?.response?.status === 403) {
          toast.error(error?.response?.data.message);
          setTimeout(() => {
            authentication.reirectToUsers();
          }, 1000);
        } else {
          toast.error("Something went wrong");
        }
      }
    },
    [id, page]
  );
  const markMetNotInterestedHandler = useCallback(
    async (userId) => {
      try {
        await markMetNotInterested(id, userId);
        toast.success("Match marked as Met - Not Interested");
        setLoading(true);
        fetchUsers(1);
        fetchMatchesStatistcis();
      } catch (error) {
        if (error?.response?.status === 401) {
          toast.error(error?.response?.data.message);
          setTimeout(() => {
            authentication.removeAuthentication();
          }, 1000);
        } else if (error?.response?.status === 403) {
          toast.error(error?.response?.data.message);
          setTimeout(() => {
            authentication.reirectToUsers();
          }, 1000);
        } else {
          toast.error("Something went wrong");
        }
      }
    },
    [id, page]
  );

  const statisticsUsersHandler = useCallback((temp) => {
    setMatchType("");
    setUsers([...temp]);
  }, []);
  const showAllHandler = useCallback((temp) => {
    setMatchType("All");
    setUsers([]);
  }, []);

  return (
    <div className="section">
      {loading && (
        <div className="loader-wrapper">
          <span class="loader" />
        </div>
      )}

      <div className="wrapper">
        <div className="table-wrapper see-matches">
          <div className="table-header">
            <h1>
              Matches Sent:{" "}
              <span style={{ color: "#f1b429" }}>
                {unlockedProfiles?.length || 0}
              </span>
            </h1>
            <button className="primary" onClick={() => setShow(!show)}>
              {show ? "Hide match statistics" : "Show match statistics"}
            </button>
          </div>
          <div className={show ? "table active" : "table"}>
            <table>
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Curated for you</th>
                  <th>Others interested in you</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>My interests</td>
                  <td>
                    <button
                      className="transparent"
                      onClick={
                        matchesStatistcis?.interested?.curated?.length > 0
                          ? () =>
                              statisticsUsersHandler(
                                matchesStatistcis?.interested?.curated
                              )
                          : null
                      }
                    >
                      {matchesStatistcis?.interested?.curated?.length || 0}
                    </button>
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>Not for me</td>
                  <td>
                    <button
                      className="transparent"
                      onClick={
                        matchesStatistcis?.notInterested?.curated?.length > 0
                          ? () =>
                              statisticsUsersHandler(
                                matchesStatistcis?.notInterested?.curated
                              )
                          : null
                      }
                    >
                      {matchesStatistcis?.notInterested?.curated?.length || 0}
                    </button>
                  </td>
                  <td>
                    <button
                      className="transparent"
                      onClick={
                        matchesStatistcis?.notInterested?.others?.length > 0
                          ? () =>
                              statisticsUsersHandler(
                                matchesStatistcis?.notInterested?.others
                              )
                          : null
                      }
                    >
                      {matchesStatistcis?.notInterested?.others?.length || 0}
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Mutual Match</td>
                  <td>
                    <button
                      className="transparent"
                      onClick={
                        matchesStatistcis?.match?.curated?.length > 0
                          ? () =>
                              statisticsUsersHandler(
                                matchesStatistcis?.match?.curated
                              )
                          : null
                      }
                    >
                      {matchesStatistcis?.match?.curated?.length || 0}
                    </button>
                  </td>
                  <td>
                    <button
                      className="transparent"
                      onClick={
                        matchesStatistcis?.match?.others?.length > 0
                          ? () =>
                              statisticsUsersHandler(
                                matchesStatistcis?.match?.others
                              )
                          : null
                      }
                    >
                      {matchesStatistcis?.match?.others?.length || 0}
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Interested In You</td>
                  <td>0</td>
                  <td>
                    <button
                      className="transparent"
                      onClick={
                        matchesStatistcis?.interestedInYou?.others?.length > 0
                          ? () =>
                              statisticsUsersHandler(
                                matchesStatistcis?.interestedInYou?.others
                              )
                          : null
                      }
                    >
                      {matchesStatistcis?.interestedInYou?.others?.length || 0}
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Awaiting your response</td>
                  <td>
                    <button
                      className="transparent"
                      onClick={
                        unlockedProfiles
                          ?.map((el) => el.matchMakerId)
                          ?.filter(
                            (el) =>
                              matchesStatistcis?.total?.others?.indexOf(el) ===
                                -1 &&
                              matchesStatistcis?.total?.curated?.indexOf(el) ===
                                -1
                          )?.length > 0
                          ? () =>
                              statisticsUsersHandler(
                                unlockedProfiles
                                  ?.map((el) => el.matchMakerId)
                                  ?.filter(
                                    (el) =>
                                      matchesStatistcis?.total?.others?.indexOf(
                                        el
                                      ) === -1 &&
                                      matchesStatistcis?.total?.curated?.indexOf(
                                        el
                                      ) === -1
                                  )
                              )
                          : null
                      }
                    >
                      {unlockedProfiles
                        ?.map((el) => el.matchMakerId)
                        ?.filter(
                          (el) =>
                            matchesStatistcis?.total?.others?.indexOf(el) ===
                              -1 &&
                            matchesStatistcis?.total?.curated?.indexOf(el) ===
                              -1
                        )?.length || 0}
                    </button>
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>Met - Not Interested</td>
                  <td>
                    <button
                      className="transparent"
                      onClick={
                        matchesStatistcis?.metNotInterested?.curated?.length > 0
                          ? () =>
                              statisticsUsersHandler(
                                matchesStatistcis?.metNotInterested?.curated
                              )
                          : null
                      }
                    >
                      {matchesStatistcis?.metNotInterested?.curated?.length ||
                        0}
                    </button>
                  </td>
                  <td>
                    <button
                      className="transparent"
                      onClick={
                        matchesStatistcis?.metNotInterested?.others?.length > 0
                          ? () =>
                              statisticsUsersHandler(
                                matchesStatistcis?.metNotInterested?.others
                              )
                          : null
                      }
                    >
                      {matchesStatistcis?.metNotInterested?.others?.length || 0}
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Met - Exploring</td>
                  <td>
                    <button
                      className="transparent"
                      onClick={
                        matchesStatistcis?.metExploring?.curated?.length > 0
                          ? () =>
                              statisticsUsersHandler(
                                matchesStatistcis?.metExploring?.curated
                              )
                          : null
                      }
                    >
                      {matchesStatistcis?.metExploring?.curated?.length || 0}
                    </button>
                  </td>
                  <td>
                    <button
                      className="transparent"
                      onClick={
                        matchesStatistcis?.metExploring?.others?.length > 0
                          ? () =>
                              statisticsUsersHandler(
                                matchesStatistcis?.metExploring?.others
                              )
                          : null
                      }
                    >
                      {matchesStatistcis?.metExploring?.others?.length || 0}
                    </button>
                  </td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "#000000", color: "#D8A225" }}>
                    Total
                  </td>
                  <td style={{ backgroundColor: "#000000", color: "#D8A225" }}>
                    {unlockedProfiles?.length -
                      (matchesStatistcis?.total?.others?.length || 0)}
                  </td>
                  <td style={{ backgroundColor: "#000000", color: "#D8A225" }}>
                    {matchesStatistcis?.total?.others?.length || 0}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="wrapper">
        <div className="table-header">
          {/* <div className="input-wrapper">
            <select
              value={matchType}
              onChange={(e) => setMatchType(e.target.value)}
            >
              <option value="" disabled style={{ display: "none" }}></option>
              <option value="all">
                All (
                {(matchesStatistcis.mutualInterests?.length || 0) +
                  (matchesStatistcis.myInterests?.length || 0) +
                  (matchesStatistcis.awaitingResponse?.length || 0) +
                  (matchesStatistcis.notInterested?.length || 0) +
                  (matchesStatistcis.interestedInYou?.length || 0)}
                )
              </option>
              <option value="mutualInterests">
                Mutual Interests (
                {matchesStatistcis.mutualInterests?.length || 0})
              </option>
              <option value="myInterests">
                My Interests ({matchesStatistcis.myInterests?.length || 0})
              </option>
              <option value="awaitingResponse">
                Awaiting Response (
                {matchesStatistcis.awaitingResponse?.length || 0})
              </option>
              <option value="notInterested">
                Not Interested ({matchesStatistcis.notInterested?.length || 0})
              </option>
              <option value="interestedInYou">
                Interested In You (
                {matchesStatistcis.interestedInYou?.length || 0})
              </option>
            </select>
          </div> */}
        </div>
        <div className="table-wrapper" ref={tableRef}>
          <div className="table-header">
            {matchType === "" && (
              <button onClick={() => showAllHandler()} className="primary">
                Show All Users
              </button>
            )}
          </div>
          <table>
            <thead>
              <th>#</th>
              <th>Name</th>
              <th>Picture</th>
              <th>Phone Number</th>
              <th>My Sync Tag</th>
              <th>My Sync %</th>
              <th>Our Sync Tag</th>
              <th>Our Sync %</th>
              <th>Status</th>
              <th>Rejection resaon</th>
              <th>Profile Managed By</th>
              <th>Push Date and Time</th>
              <th>Locked Status</th>
              <th>Push Profile</th>
              <th>Actions</th>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr>
                  <td>{(page - 1) * 50 + index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.image && <img src={item.image} />}</td>
                  <td>{item.phoneNumber}</td>
                  <td>
                    {
                      mySyncBrackets?.filter(
                        (el) =>
                          item.matchPercentage >= el.startValue &&
                          item.matchPercentage <= el.endValue
                      )[0]?.name
                    }
                  </td>
                  <td>{Number(item.matchPercentage)?.toFixed(2)}</td>
                  <td>
                    {
                      ourSyncBrackets?.filter(
                        (el) =>
                          item.mutualSync >= el.startValue &&
                          item.mutualSync <= el.endValue
                      )[0]?.name
                    }
                  </td>
                  <td>{Number(item.mutualSync)?.toFixed(2)}</td>
                  <td>{item.status}</td>
                  <td>{item.rejectionReason}</td>
                  <td>{item.servicesEngagedFor}</td>
                  <td>{item.pushedDateTime}</td>
                  <td>
                    {item.isLocked !== undefined &&
                      (item.isLocked ? "Queued" : "Unlocked")}
                  </td>
                  <td>
                    {pushedProfiles.indexOf(item._id) === -1 ? (
                      <button
                        className="info"
                        onClick={() => pushProfileHandler(item._id)}
                      >
                        Push
                      </button>
                    ) : item?.pushedDateTime ? (
                      "Pushed"
                    ) : (
                      "Curated for you"
                    )}
                  </td>
                  <td>
                    {[
                      ...matchesStatistcis?.match?.curated,
                      ...matchesStatistcis?.match?.others,
                    ]?.indexOf(item?._id) !== -1 &&
                      [
                        ...matchesStatistcis?.metExploring?.others,
                        ...matchesStatistcis?.metExploring?.curated,
                      ]?.indexOf(item._id) === -1 && (
                        <button
                          className="info"
                          onClick={() => markMetExploringHandler(item._id)}
                        >
                          Mark Met - Exploring
                        </button>
                      )}
                    {[
                      ...matchesStatistcis?.metExploring?.others,
                      ...matchesStatistcis?.metExploring?.curated,
                    ]?.indexOf(item._id) !== -1 &&
                      [
                        ...matchesStatistcis?.metNotInterested?.others,
                        ...matchesStatistcis?.metNotInterested?.curated,
                      ]?.indexOf(item._id) === -1 && (
                        <button
                          className="info"
                          onClick={() => markMetNotInterestedHandler(item._id)}
                        >
                          Mark Met - Not Interested
                        </button>
                      )}
                    {[
                      ...matchesStatistcis?.metExploring?.others,
                      ...matchesStatistcis?.metExploring?.curated,
                    ]?.indexOf(item._id) === -1 &&
                      [
                        ...matchesStatistcis?.metNotInterested?.others,
                        ...matchesStatistcis?.metNotInterested?.curated,
                      ]?.indexOf(item._id) !== -1 && (
                        <button
                          className="info"
                          onClick={() => markMetExploringHandler(item._id)}
                        >
                          Mark Met - Exploring
                        </button>
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="table-footer">
            <button
              className={page === 1 ? "secondary disabled" : "secondary"}
              onClick={page !== 1 ? () => pageHandler(false) : null}
            >
              Prev
            </button>
            <button
              className={page >= lastPage ? "secondary disabled" : "secondary"}
              onClick={page < lastPage ? () => pageHandler(true) : null}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeeMatches;
